import * as Localization from 'expo-localization';
import i18n from 'i18n-js';
import englishTranslations from './en/translation';
import spanishTranslations from './es/translation';
import frenchTranslations from './fr/translation';

// future: it may be worth looking at more advanced library for things like pluralization and more features: https://medium.com/@jamuhl/translate-your-expo-io-react-native-mobile-application-aa220b2362d2
i18n.translations = {
  en: englishTranslations,
  es: spanishTranslations,
  fr: frenchTranslations,
};

i18n.locale = Localization.locale;
i18n.fallbacks = true;

export const getText = (key: translations, options?: Record<string, any>) => {
  return i18n.t(key, options);
};

export const getLocale = () => {
  return i18n.locale.substring(0, 2);
};

export type TranslationMapping = Record<translations, string>;

export type translationsRxStatuses =
  // Workflow Status
  | 'workflow-status-in-queue'
  | 'workflow-status-out-of-stock'
  | 'workflow-status-special-order'
  | 'workflow-status-in-process'
  | 'workflow-status-awaiting-insurance-authorization'
  | 'workflow-status-awaiting-prescriber-authorization'
  | 'workflow-status-ready-for-shipping'
  | 'workflow-status-ready-for-delivery'
  | 'workflow-status-partial-fill'
  | 'workflow-status-partially-filled'
  | 'workflow-status-delivery-in-progress'
  | 'workflow-status-ready-for-pickup'
  | 'workflow-status-dispensed'
  | 'workflow-status-mail'
  | 'workflow-status-shipped'
  | 'workflow-status-delivered'
  | 'workflow-status-returned'
  | 'workflow-status-returned-to-stock'
  | 'workflow-status-cancelled'
  | 'workflow-status-expired'
  | 'workflow-status-discontinued'
  | 'workflow-status-transferred'
  | 'workflow-status-insurance-refused'
  | 'workflow-status-prescriber-refused'
  | 'workflow-status-undefined'
  // Workflow Status Pharmacy Message
  | 'workflow-status-in-queue-message'
  | 'workflow-status-out-of-stock-message'
  | 'workflow-status-special-order-message'
  | 'workflow-status-in-process-message'
  | 'workflow-status-awaiting-insurance-authorization-message'
  | 'workflow-status-awaiting-prescriber-authorization-message'
  | 'workflow-status-ready-for-shipping-message'
  | 'workflow-status-ready-for-delivery-message'
  | 'workflow-status-partial-fill-message'
  | 'workflow-status-partially-filled-message'
  | 'workflow-status-delivery-in-progress-message'
  | 'workflow-status-ready-for-pickup-message'
  | 'workflow-status-dispensed-message'
  | 'workflow-status-mail-message'
  | 'workflow-status-shipped-message'
  | 'workflow-status-delivered-message'
  | 'workflow-status-returned-message'
  | 'workflow-status-returned-to-stock-message'
  | 'workflow-status-cancelled-message'
  | 'workflow-status-expired-message'
  | 'workflow-status-discontinued-message'
  | 'workflow-status-transferred-message'
  | 'workflow-status-insurance-refused-message'
  | 'workflow-status-prescriber-refused-message'
  | 'workflow-status-undefined-message'
  // Rx Status
  | 'rx-status-no-refills'
  | 'rx-status-expired'
  | 'rx-status-too-soon'
  | 'rx-status-not-found'
  | 'rx-status-controlled'
  | 'rx-status-narcotic'
  | 'rx-status-transferred'
  | 'rx-status-discontinued'
  | 'rx-status-on-hold'
  | 'rx-status-deleted'
  | 'rx-status-fillable'
  | 'rx-status-partially-filled'
  | 'rx-status-out-of-stock'
  | 'rx-status-special-order'
  | 'rx-status-deceased'
  | 'rx-status-undefined'
  // Rx Status Messages
  | 'rx-status-no-refills-message'
  | 'rx-status-expired-message'
  | 'rx-status-too-soon-message'
  | 'rx-status-not-found-message'
  | 'rx-status-controlled-message'
  | 'rx-status-narcotic-message'
  | 'rx-status-transferred-message'
  | 'rx-status-discontinued-message'
  | 'rx-status-on-hold-message'
  | 'rx-status-deleted-message'
  | 'rx-status-fillable-message'
  | 'rx-status-partially-filled-message'
  | 'rx-status-out-of-stock-message'
  | 'rx-status-special-order-message'
  | 'rx-status-deceased-message'
  | 'rx-status-undefined-message'
  // Order status
  | 'order-status-received'
  | 'order-status-filled'
  | 'order-status-out-for-delivery'
  | 'order-status-ready-for-pickup'
  | 'order-status-picked-up'
  | 'order-status-mailed'
  | 'order-status-shipped'
  | 'order-status-delivered'
  | 'order-status-return-to-stock'
  | 'order-status-info-unavailable'
  | 'order-status-undefined'
  // Order Tracker
  | 'order-progress-not-started'
  | 'order-progress-started'
  | 'order-progress-completed'
  | 'order-progress-undefined'
  // Refill Status
  | "refill-status-passed"
  | "refill-status-not-passed"
  | "refill-status-filter-all";

export type translations =
  | translationsRxStatuses
  | 'signup'
  | 'login'
  | 'username'
  | 'password'
  | 'dashboard'
  | 'medications'
  | 'messages'
  | 'profile'
  | 'get-care'
  | 'lets-get-to-know-you'
  | 'lets-get-to-know-you-details'
  | 'welcome'
  | 'logout'
  | 'front'
  | 'back'
  | 'otc-medications'
  | 'get-mobile-app'
  | 'go-to-form'
  | 'next'
  | 'scan-barcode'
  | 'scanned'
  | 'save'
  | 'forgot-password'
  | 'back-to-login'
  | 'camera-access-denied'
  | 'username-is-required'
  | 'password-is-required'
  | 'view-full-profile'
  | 'insurance-card'
  | 'barcode-scanner'
  | 'english'
  | 'spanish'
  | 'french'
  | 'contact-info'
  | 'yes'
  | 'no-puc-access'
  | 'no'
  | 'continue'
  | 'first-name'
  | 'first-name-is-required'
  | 'last-name'
  | 'last-name-is-required'
  | 'email-short'
  | 'email'
  | 'email-is-required'
  | 'email-continue'
  | 'enter-patient-information'
  | 'mobile-number'
  | 'delete-image'
  | 'make-primary'
  | 'updated'
  | 'mobile-number-is-required'
  | 'birth-date'
  | 'birthdate'
  | 'birth-date-is-required'
  | 'retrieving'
  | 'lets-get-started'
  | 'front-of-card'
  | 'back-of-card'
  | 'coming-up'
  | 'view'
  | 'no-upcoming-appointments'
  | 'no-refills-due'
  | 'add-front-of-card'
  | 'photo'
  | 'add-back-of-card'
  | 'cancel'
  | 'retake'
  | 'use-photo'
  | 'password-must-include'
  | 'add-card'
  | 'primary-secondary-photo-id'
  | 'at-least-8-characters'
  | 'at-least-1-number'
  | 'at-least-1-capital-letter'
  | 'at-least-1-lowercase-letter'
  | 'at-least-1-special-character'
  | 'privacy-policy-error'
  | 'privacy-policy-agree'
  | 'privacy-policy-email-signup'
  | 'pharmacy-privacy-policy'
  | 'terms-and-conditions'
  | 'terms-of-service'
  | 'terms-of-service-error'
  | 'terms-of-service-agree'
  | 'terms-of-service-email-signup'
  | 'and'
  | 'submit'
  | '2-of-2-agreements'
  | 'medical-info-step-3'
  | 'password-entry-does-not-meet-criteria'
  | 'password-entries-do-not-match'
  | 'create-your-account'
  | 'please-enter-your-new-password'
  | 'email-is-not-valid'
  | 'phone-is-not-valid'
  | 'create-account'
  | 'confirm-password'
  | 'patient-info-step'
  | 'communications-language'
  | 'prescriptions-communications'
  | 'automated-voice-call'
  | 'patient-select'
  | 'after-adding-patient'
  | 'text-message'
  | 'account-setup'
  | 'app-notifications'
  | 'all-other-pharmacy-communications'
  | 'same-time-preferences'
  | 'additional-information'
  | 'visually-impaired'
  | 'hearing-impaired'
  | 'easy-open-bottle-caps'
  | 'personal-info-step-2'
  | 'country'
  | 'country-is-required'
  | 'state'
  | 'state-is-required'
  | 'city'
  | 'city-is-required'
  | 'street-address'
  | 'street-address-is-required'
  | 'street-address-line-2'
  | 'zip-code'
  | 'zip-code-is-required'
  | 'gender'
  | 'gender-is-required'
  | 'male'
  | 'female'
  | 'verification-code-resent'
  | 'please-enter-code'
  | 'didnt-receive-email'
  | 'please-check-spam-folder'
  | 'didnt-receive-text-message'
  | 'phone-number-is-sms-capable'
  | 'standard-text-messages-apply'
  | 'resend-code'
  | 'reset-password'
  | 'reset-link-sent'
  | 'please-enter-email'
  | 'how-would-you-like-to-receive-reset-link'
  | 'send-reset-link'
  | 'create-new-password'
  | 'for-security-reasons'
  | 'update-password'
  | 'password-expiring-soon'
  | 'password-expire-in-7-days'
  | 'update-password-now'
  | 'confirm-new-password'
  | 'confirm-update-password'
  | 'new-password'
  | 'change-password'
  | 'your-account'
  | 'login-with-email'
  | 'login-enter-email'
  | 'go-to-login'
  | 'return-to-login'
  | 'passwords-must-match'
  | 'dismiss'
  | 'load-more'
  | 'loading'
  | 'open-map'
  | 'input-field'
  | 'text-field'
  | 'show-hide-password'
  | 'search'
  | 'select'
  | 'use'
  | 'no-options'
  | 'email-or-password-incorrect'
  | 'google-login'
  | 'google-continue'
  | 'google-signup'
  | 'google-login-failed'
  | 'unable-to-complete-registration'
  | 'unable-to-update-account'
  | 'unable-to-complete-verification'
  | 'unable-to-resend-verification-code'
  | 'error-submitting'
  | 'greeting'
  | 'unable-to-send-reset-link'
  | 'unable-to-reset-password'
  | 'store-selector-change'
  | 'store-selector-search-address'
  | 'store-selector-change-store'
  | 'store-selector-your-store'
  | 'store-selector-nearby-store'
  | 'store-selector-close'
  | 'store-selector-select'
  | 'store-selector-no-stores-error'
  | 'feedback'
  | 'feedback-give-feedback'
  | 'feedback-give-your-feedback'
  | 'feedback-please-enter-feedback'
  | 'feedback-how-to-improve-app'
  | 'feedback-give-feedback-about-pharmacy'
  | 'feedback-sent'
  | 'feedback-thank-you'
  | 'feedback-what-type-of-feedback'
  | 'feedback-target-is-required'
  | 'feedback-feedback'
  | 'feedback-feedback-is-required'
  | 'feedback-allowContact'
  | 'feedback-thanks-message'
  | 'feedback-reach-you-message'
  | 'feedback-back-to-profile'
  | 'feedback-error-occurred'
  | 'feedback-contact-info'
  | 'feedback-edit'
  | 'feedback-email-address'
  | 'feedback-phone-number'
  | 'feedback-app'
  | 'feedback-location'
  | 'refill-card-medication'
  | 'refill-card-due-date'
  | 'prescription-card-day-supply'
  | 'prescription-card-filled-on'
  | 'prescription-card-last-filled'
  | 'prescription-card-last-ordered'
  | 'prescription-card-refill-due'
  | 'remove'
  | 'prescription-card-refills'
  | 'prescription-card-dob'
  | 'prescription-find-server-error'
  | 'medications-fill-your-prescription'
  | 'medications-ready-to-be-filled'
  | 'medications-other-upcoming-refills'
  | 'insurance-card-upload-error'
  | 'primary-insurance-add-your-information'
  | 'secondary-insurance-add-your-information'
  | 'placeholder-step'
  | 'photoId'
  | 'form'
  | 'import-plan'
  | 'instructions'
  | 'instructions-api'
  | 'pdf-instructions'
  | 'open-pdf'
  | 'insurance-take-card-picture'
  | 'photo-take-card-picture'
  | 'insurance-bring-to-store'
  | 'camera-mask-label'
  | 'take-photo'
  | 'choose-photo'
  | 'anonymous-refill'
  | 'new-form'
  | 'date-is-not-valid'
  | 'verify-your-email'
  | 'verify-your-phone'
  | 'please-enter-your-information'
  | 'email-address'
  | 'user-not-found'
  | 'invalid-confirmation-code'
  | 'contact-support'
  | 'patient-stores'
  | 'many-failed-attempts'
  | 'not-authorized'
  | 'done'
  | 'do-this-later'
  | 'entered-prescription-manually'
  | 'your-prescriptions'
  | 'add'
  | 'phone-number-footer'
  | 'fax'
  | 'digital-pharmacist'
  | 'pharmacy-website'
  | 'drug-name'
  | 'name-not-available'
  | 'birthdate-not-available'
  | 'appointments-calendar'
  | 'add-override'
  | 'not-implemented'
  | 'to'
  | 'chat'
  | 'preview'
  | 'download'
  | 'new-appointment'
  | 'drug-name-is-required'
  | 'rx-number-is-required'
  | 'rx-number-is-duplicated'
  | 'rx-number'
  | 'add-your-prescriptions'
  | 'retrieve-your-prescriptions'
  | 'other-meds'
  | 'try-again'
  | 'step'
  | 'patient-dob'
  | 'patient-dob-is-required'
  | 'patient-last-name-is-required'
  | 'patient-last-name'
  | 'we-found-your-medications-alert-title'
  | 'unable-to-locate-your-prescription-alert-title'
  | 'unable-to-locate-your-prescription-alert-description'
  | 'prescription-information'
  | 'integrated-prescription-flow-description'
  | 'change'
  | 'location'
  | 'locations'
  | 'forms'
  | 'tasks'
  | 'patients'
  | 'refill-submissions'
  | 'refill-submissions-header'
  | 'user'
  | 'users'
  | 'new-user'
  | 'user-details'
  | 'title'
  | 'role'
  | 'last-active'
  | 'registered'
  | 'activity'
  | 'show'
  | 'view-activity-logs'
  | 'resend-invite'
  | 'delete'
  | 'delete-confirmation'
  | 'cannot-undo-action'
  | 'email-is-invalid'
  | 'passwords-do-not-match'
  | 'password-does-not-meet-criteria'
  | 'follow-reset-password-instructions'
  | 'pharmacy-reset-link-sent'
  | 'need-help'
  | 'set-your-preferences'
  | 'find-your-store'
  | 'confirm-your-store'
  | 'link-expired'
  | 'reset-unsuccessful'
  | 'order-submitted'
  | 'order'
  | 'picking-up-at'
  | 'ready-today'
  | 'notify-you'
  | 'apple-signup'
  | 'apple-continue'
  | 'apple-login'
  | 'prescribed-for'
  | 'last-filled-on'
  | 'last-ordered-on'
  | 'auto-refills'
  | 'auto-refill'
  | 'refills-left'
  | 'quantity-dispensed'
  | 'days-supply'
  | 'directions'
  | 'questions-about-medication'
  | 'ask-your-pharmacist'
  | 'message'
  | 'refill'
  | 'child'
  | 'spouse'
  | 'parent'
  | 'grandparent'
  | 'grandchild'
  | 'pet'
  | 'partner'
  | 'other'
  | 'resources'
  | 'medication-guides'
  | 'how-to-take'
  | 'patient-education-sheets'
  | 'side-effects-interactions'
  | 'prescription-written'
  | 'prescription-by'
  | 'call-prescriber'
  | 'hi'
  | 'create-your-password'
  | 'i-have-read-and-agree'
  | 'pharmacy-terms-and-conditions'
  | 'privacy-policy'
  | 'terms-and-conditions-required'
  | 'privacy-policy-required'
  | 'new-patient'
  | 'i-am-a-new-patient'
  | 'patient-details'
  | 'edit'
  | 'date-of-birth'
  | 'date-of-birth-short'
  | 'phone-number'
  | 'gender-text'
  | 'language'
  | 'conditions'
  | 'easy-open-bottle'
  | 'med-sync'
  | 'photo-id'
  | 'image'
  | 'notification'
  | 'app-notification'
  | 'list'
  | 'providers'
  | 'notes'
  | 'internal-use-only'
  | 'continuation-disclaimer'
  | 'to-get-started-choose'
  | 'face-id'
  | 'set-my-store'
  | 'medical-equipment-immunization'
  | 'services-offered'
  | 'en-es-fr'
  | 'languages-spoken'
  | 'holiday-hours-vary'
  | 'open-hours'
  | 'open-today'
  | 'store-hours'
  | 'upload-csv'
  | 'personal-info'
  | 'will-pickup-prescription-today'
  | 'field-required'
  | 'will-pickup-prescription-urgently'
  | 'order-details'
  | 'method'
  | 'note-for-pharmacy'
  | 'note-for-pharmacy-not-optional'
  | 'review'
  | 'revoke'
  | 'insurance-is-required'
  | 'login-attempts-exceeded'
  | 'max-attempts-exceeded'
  | 'account-locked-reason'
  | 'send-me-email'
  | 'security-reason'
  | 'send-me-sms'
  | 'insurance-change-last-refill'
  | 'prescription-ready-each-month'
  | 'add-people-under-care-message-pt-1'
  | 'add-people-under-care-message-pt-2'
  | 'prescriptions-is-required'
  | 'over-the-counter-medications'
  | 'please-review-messages-and-proceed'
  | 'how-to-proceed'
  | 'enter-patient-email'
  | 'add-patient-request-submit'
  | 'first-name-is-not-valid'
  | 'last-name-is-not-valid'
  | 'provide-an-email'
  | 'request-sent'
  | 'revoke-access-for-authorized-caregiver'
  | 'legal-guardian-confirmation-for-person'
  | 'confirmation'
  | 'add-minor-or-pet-patient'
  | 'your-allergies'
  | 'legal-guardian-agreement'
  | 'meds'
  | 'orders'
  | 'reminders'
  | 'approval-needed'
  | 'person-has-an-account'
  | 'add-another-person'
  | 'add-another'
  | 'go-home'
  | 'allow-pharmacy-contact-prescriber'
  | 'continue-with-selected-items'
  | 'go-back'
  | 'go-back-update-order'
  | 'thank-you-for-choosing'
  | 'add-people-under-care'
  | 'add-person'
  | 'return-to-order-details'
  | 'we-will-contact-prescriber'
  | 'unable-to-process-items'
  | 'please-review-the-messages-below'
  | 'ready-to-be-filled'
  | 'other-details'
  | 'other-information'
  | 'unable-to-submit-people-under-care-request'
  | 'relationship-to-me'
  | 'notice'
  | 'insurance'
  | 'appointments'
  | 'account'
  | 'add-medications'
  | 'add-meds-text'
  | 'caregiver-approval-agreement'
  | 'no-known-allergies'
  | 'known-allergies'
  | 'allergies-required'
  | 'more-information'
  | 'monthly-prescriptions'
  | 'as-an-authorized-caregiver'
  | 'manage-prescriptions-digitally'
  | 'order-refills'
  | 'delivery'
  | 'mail'
  | 'curbside'
  | 'relation-to-primary-user'
  | 'would-like-easy-open-caps'
  | 'prefers-easy-open-caps'
  | 'prefers-safety-caps'
  | 'pick-up-prescriptions-for'
  | 'discuss-care-with'
  | 'manage-health-and-clinic'
  | 'access-validity-puc'
  | 'update-puc-list'
  | 'patient-record-created'
  | 'prescriptions-at-file'
  | 'request-to-transfer'
  | 'do-this-medications-tab'
  | 'skip-this-step'
  | 'using-the-phone-number-on-file'
  | 'close'
  | 'closed'
  | 'store-info'
  | 'your-password-has-been-successfully-changed'
  | 'update-weak-password'
  | 'people-under-your-care'
  | 'people-under-your-care-empty-page'
  | 'medical-info'
  | 'none'
  | 'add-person-or-pet'
  | 'refill-details'
  | 'success'
  | 'prescription-no'
  | 'name'
  | 'status'
  | 'submitted'
  | 'channel'
  | 'delivery-method'
  | 'refill-comments'
  | 'patient-info'
  | 'details'
  | 'phone'
  | 'request-status'
  | 'every-n-months'
  | 'address'
  | 'email-text'
  | 'prescription'
  | 'patient'
  | 'date-submitted'
  | 'allergies-colon'
  | 'medical-conditions'
  | 'refill-past-due'
  | 'refill-due'
  | 'store'
  | 'app'
  | 'manage-prescriptions'
  | 'pick-up-prescriptions'
  | 'discuss-care'
  | 'manage-health'
  | 'add-patient'
  | 'if-you-authorize-person'
  | 'access-validity'
  | 'revoke-access'
  | 'authorized-on'
  | 'revoke-access-in-settings'
  | 'review-request'
  | 'authorize'
  | 'decline'
  | 'puc-permission-access-to-records'
  | 'authorized-caregivers'
  | 'medications-empty-state-title'
  | 'medications-empty-state-description'
  | 'medications-reminders-empty-state-title'
  | 'medications-reminders-empty-state-description'
  | 'add-meds'
  | 'transfer-meds'
  | 'choose-from-the-list'
  | 'select-one-item'
  | 'pickup'
  | 'select-an-option'
  | 'please-confirm-the-info-matches-prescription-label'
  | 'register-confirmation-heading'
  | 'register-confirmation-body'
  | 'orders-tab-empty-state-title'
  | 'orders-tab-empty-state-description'
  | 'start-an-order'
  | 'show-locations'
  | 'first-name-edit-user'
  | 'last-name-edit-user'
  | 'information'
  | 'schedule'
  | 'find-time'
  | 'learn-more'
  | 'next-available'
  | 'not-available'
  | 'intro'
  | 'enter-email'
  | 'create-password'
  | 'register-confirmation'
  | 'register'
  | 'reset-link'
  | 'reset-successful'
  | 'register-details'
  | 'phone-verification'
  | 'patient-pharmacy-stores'
  | 'add-prescription'
  | 'refill-guest'
  | 'home'
  | 'settings'
  | 'store-location'
  | 'expiring-soon'
  | 'minutes'
  | 'book-appointment'
  | 'upcoming'
  | 'past'
  | 'patient-insurance'
  | 'barcode'
  | 'patient-preferences'
  | 'feedback-success'
  | 'people-under-care'
  | 'add-patient-under-care'
  | 'add-patient-under-care-info'
  | 'add-patient-under-care-email'
  | 'add-patient-under-care-confirmation'
  | 'insurance-and-id-cards'
  | 'primary'
  | 'secondary'
  | 'id-card'
  | 'patient-under-care-list'
  | 'automatic-prescription-flow'
  | 'section-on-the-roadmap'
  | 'bottle-cap-preference'
  | 'success-automatic-prescription-flow'
  | 'coming-soon'
  | 'allergies-conditions-caregivers'
  | 'insurance-id-card'
  | 'patient-forms'
  | 'communication-preferences'
  | 'sign-out'
  | 'select-person'
  | 'myself'
  | 'confirm'
  | 'on-hold'
  | 'rejected-reason-unknown'
  | 'show-more'
  | 'allergies'
  | 'street1'
  | 'street2'
  | 'gender-on-insurance'
  | 'preferred-language'
  | 'notification-preferences'
  | 'auto-voice-call'
  | 'edit-patient'
  | 'show-less'
  | 'appointment-scheduled'
  | 'booking-error'
  | 'error-loading-slots'
  | 'error-loading-patient-record'
  | 'error-updating-patient-record'
  | 'error-booking-appointment'
  | 'error-loading-appointments'
  | 'when'
  | 'who'
  | 'where'
  | 'care-notes'
  | 'reschedule'
  | 'about-this-app'
  | 'copyright-lumistry'
  | 'all-rights-reserved'
  | 'version'
  | 'cancel-appointment'
  | 'reschedule-appointment'
  | 'error-loading-appointment'
  | 'error-loading-appointment-type'
  | 'refill-medications'
  | 'refill-medical-infos'
  | 'refill-review'
  | 'refill-other-information'
  | 'refill-prescription-method'
  | 'refill-insurance-method'
  | 'refill-confirmation'
  | 'refill-order-details'
  | 'good-morning'
  | 'good-afternoon'
  | 'good-evening'
  | 'you-are-chatting-with'
  | 'new-message'
  | 'no-messages'
  | 'messages-have-a-question'
  | 'new-message-placeholder'
  | 'general'
  | 'billing'
  | 'med-info'
  | 'side-effects'
  | 'message-subject-label'
  | 'another-account'
  | 'send'
  | 'appointment-rescheduled'
  | 'error-rescheduling-appointment'
  | 'cancel-appointment-confirmation'
  | 'appointment-canceled'
  | 'previously-scheduled'
  | 'cancel-note'
  | 'cancel-error'
  | 'error-cancel-appointment'
  | 'keep'
  | 'of'
  | 'tomorrow'
  | 'today'
  | 'canceled'
  | 'events'
  | 'error-field-number'
  | 'no-appointments'
  | 'no-services'
  | 'video-header'
  | 'videos'
  | 'videos-filter'
  | 'videos-clear'
  | 'videos-all'
  | 'videos-medication'
  | 'videos-condition'
  | 'videos-administration'
  | 'videos-missing-medication-video'
  | 'videos-general-health'
  | 'videos-search'
  | 'videos-share'
  | 'videos-category'
  | 'videos-title'
  | 'videos-language-spanish'
  | 'videos-language-english'
  | 'videos-language-french'
  | 'videos-duration'
  | 'videos-url'
  | 'copied-to-clipboard'
  | 'videos-med-guides'
  | 'videos-transcript'
  | 'delete-confirmation-modal'
  | 'service'
  | 'create-appointment'
  | 'limit-booking-frequency'
  | 'limit-booking-duration'
  | 'limit-future-bookings'
  | 'before-event'
  | 'after-event'
  | 'into-future'
  | 'within-date-range'
  | 'add-limit'
  | 'calendar-days'
  | 'business-days'
  | 'per-day'
  | 'per-week'
  | 'per-month'
  | 'no-buffer-time'
  | 'order-refill'
  | 'content-box-title'
  | 'cancellations'
  | 'duration'
  | 'time'
  | 'check-later'
  | 'actions'
  | 'service-record-enabled-tooltip'
  | 'service-record-disabled-tooltip'
  | 'copy-link-button-tooltip'
  | 'edit-service-record-tooltip'
  | 'delete-service-record-tooltip'
  | 'selecting-all-pages'
  | 'select-all-pages'
  | 'selecting-current-page'
  | 'select-current-page'
  | 'upcoming-refill'
  | 'send-message'
  | 'unselect-all'
  | 'login-attempts-exceeded'
  | 'max-attempts-exceeded'
  | 'account-locked-reason'
  | 'send-me-email'
  | 'security-reason'
  | 'send-me-sms'
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'
  | 'sunday'
  | 'good-afternoon'
  | 'good-evening'
  | 'others'
  | 'reorder'
  | 'others'
  | 'generic-bulk-confirmation-modal'
  | 'task'
  | 'bulk-assignee-confirmation-modal'
  | 'bulk-due-date-confirmation-modal'
  | 'unresolved'
  | 'resolved'
  | 'in_progress'
  | 'on_hold'
  | 'task-resolve'
  | 'mark-resolved'
  | 'mark-in-progress'
  | 'mark-flagged'
  | 'mark-unresolved'
  | 'mark-on-hold'
  | 'change-assignee'
  | 'change-due'
  | 'task-in-progress'
  | 'task-flag'
  | 'task-filters'
  | 'error'
  | 'options'
  | 'add-new-option'
  | 'edit-columns'
  | 'add-new-column'
  | 'edit-rows'
  | 'add-new-row'
  | 'import'
  | 'grid'
  | 'browse'
  | 'unknown'
  | 'assignee'
  | 'year'
  | 'month'
  | 'apply-to-all'
  | 'all-statuses'
  | 'all-priorities'
  | 'all-visibilities'
  | 'all-types'
  | 'person'
  | 'edit-form-title'
  | 'subtitle'
  | 'required-field'
  | 'edit-title'
  | 'edit-subtitle'
  | 'edit-checkbox-label'
  | 'display-name'
  | 'length'
  | 'restriction'
  | 'account-holder'
  | 'monday-abbreviation'
  | 'tuesday-abbreviation'
  | 'wednesday-abbreviation'
  | 'thursday-abbreviation'
  | 'friday-abbreviation'
  | 'saturday-abbreviation'
  | 'sunday-abbreviation'
  | 'frequency'
  | 'set-to-default'
  | 'end-date'
  | 'custom-domain'
  | 'sort-by-due-date'
  | 'sort-by-assignee'
  | 'sort-by-task-summary'
  | 'chinese'
  | 'vietnamese'
  | 'german'
  | 'korean'
  | 'russian'
  | 'fullname'
  | 'please-upload-image'
  | 'signature'
  | 'type-your-question'
  | 'select-single-option'
  | 'select-multiple-options'
  | 'number'
  | 'please-select'
  | 'every-week'
  | 'every'
  | 'weeks'
  | 'every-month'
  | 'months'
  | 'last-day'
  | 'reoccurrence-must-have-context'
  | 'start-date-lower-end-date'
  | 'medical-conditions-without-colon'
  | 'enter-valid-phone-number'
  | 'ascending'
  | 'descending'
  | 'name-is-required'
  | 'availability-name'
  | 'service-required'
  | 'maximum-input-value'
  | 'week'
  | 'day'
  | 'date-overrides'
  | 'not-started'
  | 'over-the-counter'
  | 'delete-task'
  | 'venue'
  | 'private'
  | 'public'
  | 'your-pharmacy'
  | 'edit-availability-record-tooltip'
  | 'delete-availability-record-tooltip'
  | 'availability'
  | 'new-chat-message'
  | 'your-refill-is-ready'
  | 'hi-your-refill-is-ready'
  | 'patient-is-required'
  | 'subject-is-required'
  | 'message-or-attachment-is-required'
  | 'message-characters-limit-five-thousand'
  | 'find-template'
  | 'subject'
  | 'note-inbox-messages-should-be-clinical-in-nature'
  | 'all'
  | 'unread'
  | 'unread-by-patient'
  | 'includes-me'
  | 'created-by-me'
  | 'last-seven-days'
  | 'filters'
  | 'clear'
  | 'text-formatting'
  | 'send-attachment'
  | 'type-your-message'
  | 'mark-as-unread'
  | 'chat-messages'
  | 'find-patient'
  | 'new-subject'
  | 'add-mobile-number-send-message'
  | 'add-mobile-number'
  | 'newest'
  | 'oldest'
  | 'a-z'
  | 'new-chat'
  | 'inbox'
  | 'auto'
  | 'previous-week'
  | 'current-week'
  | 'next-week'
  | 'secure-message'
  | 'unable-to-process'
  | 'try-again-later'
  | 'message-pharmacy'
  | 'call-pharmacy'
  | 'tasks-grid-no-results-title'
  | 'tasks-grid-no-results-subtitle'
  | 'get-directions'
  | 'explore-other-locations'
  | 'error-getting-locations'
  | 'zip-code-is-not-valid'
  | 'street-1-is-not-valid'
  | 'street-2-is-not-valid'
  | 'allergies-are-not-valid'
  | 'conditions-are-not-valid'
  | 'make-appointment'
  | 'find-service-at-location'
  | 'get-more-stores'
  | 'no-medication-guide'
  | 'error-loading-patients-under-care'
  | 'add-new-patient'
  | 'after-adding-patient-message'
  | 'pending-requests'
  | 'add-calendar-event'
  | 'by-application'
  | 'by-email'
  | 'by-sms'
  | 'by-voice'
  | 'error-loading-patient'
  | 'in'
  | 'ago'
  | 'month-short'
  | 'hour-short'
  | 'day-short'
  | 'missing-important-patient-record-data'
  | 'google'
  | 'apple'
  | 'outlook'
  | 'reset-filters'
  | 'calendar'
  | 'appointment-panel'
  | 'age'
  | 'confirmed'
  | 'pending'
  | 'rejected'
  | 'service-description'
  | 'additional-info'
  | 'more'
  | 'less'
  | 'month-capital'
  | 'week-capital'
  | 'day-capital'
  | 'dont-see-service'
  | 'service-category'
  | 'see-order-details'
  | 'team'
  | 'personal'
  | 'sent'
  | 'ordered'
  | 'reschedule-note'
  | 'characters-count'
  | 'maxim-count-attachments'
  | 'selecting-files-wrong'
  | 'files-sending-error-try-again'
  | 'create-form-first'
  | 'pharmacy'
  | 'pharmacy-general-info'
  | 'pharmacy-edit-general-info'
  | 'pharmacy-general-details'
  | 'pharmacy-general-info-limited-brand-name'
  | 'pharmacy-company-name'
  | 'pharmacy-brand-full-name'
  | 'pharmacy-ncpdp'
  | 'pharmacy-brand-admin'
  | 'pharmacy-parent-company'
  | 'pharmacy-npi'
  | 'pharmacy-pms'
  | 'pharmacy-facebook'
  | 'pharmacy-instagram'
  | 'pharmacy-twitter'
  | 'pharmacy-contact-info'
  | 'pharmacy-edit-contact-info'
  | 'pharmacy-email-address'
  | 'pharmacy-phone-number'
  | 'pharmacy-fax-number'
  | 'pharmacy-mailing-address'
  | 'pharmacy-billing-address'
  | 'pharmacy-brand-name-length'
  | 'pharmacy-departments'
  | 'pharmacy-names-details'
  | 'pharmacy-edit-department'
  | 'pharmacy-delete-department'
  | 'pharmacy-delete-department-info'
  | 'pharmacy-departments-name'
  | 'pharmacy-departments-hours'
  | 'pharmacy-departments-hours-subtitle'
  | 'pharmacy-departments-name-required'
  | 'pharmacy-departments-add-hours'
  | 'pharmacy-departments-remove-hours'
  | 'pharmacy-holiday-closures'
  | 'pharmacy-holiday-closures-description'
  | 'pharmacy-additional-closures'
  | 'pharmacy-additional-closures-description'
  | 'pharmacy-additional-closures-active'
  | 'pharmacy-additional-closures-inactive'
  | 'pharmacy-additional-name-required'
  | 'pharmacy-additional-date-required'
  | 'pharmacy-additional-hour-required'
  | 'pharmacy-additional-label'
  | 'new'
  | 'url-is-not-valid'
  | 'create-first-form'
  | 'ok'
  | 'upload'
  | 'credentials'
  | 'lumistry-api-key'
  | 'import-forms'
  | 'number-of-forms'
  | 'replace'
  | 'front-photo-missing'
  | 'back-photo-missing'
  | 'home-account-subheading'
  | 'home-medications-subheading'
  | 'home-messages-subheading'
  | 'home-appointments-subheading'
  | 'home-puc-subheading'
  | 'update-personal-info'
  | 'name-phone-email'
  | 'no-allergies'
  | 'forms-fill-out'
  | 'forms-filled-in'
  | 'health-info'
  | 'insurance-card-reminder'
  | 'changed-insurance'
  | 'pre-appointment-checklist'
  | 'allergy-info'
  | 'easy-open-bottle-caps-confirm'
  | 'message-body-is-required'
  | 'verify-patient'
  | 'verify-patient-warning'
  | 'no-forms-required'
  | 'form-unavailable'
  | 'form-not-started'
  | 'form-submitted'
  | 'preparing-submission'
  | 'download-will-begin'
  | 'delete-submission-confirmation'
  | 'not-entered'
  | 'submitted-on'
  | 'download-pdf'
  | 'submission-modal'
  | 'medical-information'
  | 'yes-easy-caps'
  | 'no-safety-caps'
  | 'easy-open-bottle-caps-description'
  | 'checklist-complete'
  | 'checklist-incomplete'
  | 'service-status-updated'
  | 'error-loading-form'
  | 'close-confirmation-question'
  | 'close-confirmation-text'
  | 'close-confirmation-exit'
  | 'close-confirmation-keep-appointment'
  | 'password-reset-subheading'
  | 'save-password'
  | 'venue-in-person'
  | 'venue-virtual'
  | 'venue-over-the-phone'
  | 'account-not-found-heading'
  | 'account-not-found-subheading'
  | 'account-not-found-subheading-sso'
  | 'get-the-app'
  | 'manage-subscription'
  | 'invalid-email-format'
  | 'rx-order-progress'
  | 'prescriptions-filled'
  | 'all-tasks'
  | 'every-n-weeks'
  | 'edit-reschedule-this-appointment'
  | 'cancel-this-appointment'
  | 'message-this-patient'
  | 'contact-pharmacy-for-policy-info'
  | 'prescriptions-empty-state-paragraph-one'
  | 'prescriptions-empty-state-paragraph-two'
  | 'prescriptions-empty-state-button-text'
  | 'otc-empty-state-paragraph-one'
  | 'otc-empty-state-paragraph-two'
  | 'otc-empty-state-button-text'
  | 'viewing-medications-for'
  | 'mark-unread'
  | 'error-updating-appointment'
  | 'error-occurred-title'
  | 'error-occurred-description'
  | 'contact-prescription-ready'
  | 'order-refills-prescriptions'
  | 'remove-patient'
  | 'task-type'
  | 'characters'
  | 'task-type-required'
  | 'task-type-title-exceeded'
  | 'task-type-description-required'
  | 'task-type-description-exceeded'
  | 'delete-task-type-confirm'
  | 'user-roles'
  | 'task-status'
  | 'task-assigned-to'
  | 'task-priority'
  | 'task-created-by'
  | 'task-due-by'
  | 'task-visibility'
  | 'integration'
  | 'api-host'
  | 'server-address'
  | 'server-port'
  | 'api-key'
  | 'setup-integration'
  | 'integration-missing'
  | 'edit-integration'
  | 'pharmacy-software'
  | 'npi'
  | 'dob-edit-user'
  | 'prescription-info'
  | 'add-to-list'
  | 'adding-prescriptions-for'
  | 'last-submission'
  | 'last-modified'
  | 'no-submissions'
  | 'hours'
  | 'date'
  | 'flagged'
  | 'recurring'
  | 'overdue'
  | 'trash'
  | 'api-required'
  | 'assignee-required'
  | 'drag-change-order'
  | 'remove-item'
  | 'form-submission-not-deleting'
  | 'download-all-submissions'
  | 'no-submissions-download'
  | 'copy-link'
  | 'form-was-imported'
  | 'access-private-public'
  | 'cannot-delete-task-type'
  | 'personal-task-assign'
  | 'new-service'
  | 'cannot-edit-task-type'
  | 'high-priority'
  | 'submissions'
  | 'search-forms'
  | 'no-submissions-found'
  | 'delete-availability-in-use-tooltip'
  | 'add-patients-start-messaging'
  | 'upload-patients-csv'
  | 'photo-id-2'
  | 'find-meds'
  | 'rx-number-must-be-numeric'
  | 'no-notes-attached'
  | 'error-occurred-integration-save'
  | 'error-occurred-integration-load-save'
  | 'integration-saved'
  | 'hide'
  | 'server-address-required'
  | 'start-time-required'
  | 'end-time-required'
  | 'custom-filters'
  | 'appointments-filters-info-title'
  | 'service-name-info-box'
  | 'service-category-info-box'
  | 'applied-to-all-locations'
  | 'appointment-notes-update-error'
  | 'add-file'
  | 'add-image'
  | 'pharmacy-npi-number'
  | 'my-tasks'
  | 'access-denied'
  | 'check-back-later'
  | 'contact-administration'
  | 'new-messages'
  | 'this-week'
  | 'this-month'
  | 'older'
  | 'time-sensitive'
  | 'profile-settings'
  | 'switch-user'
  | 'services'
  | 'automated-actions'
  | 'wellness-classes'
  | 'same-mailing-address'
  | 'audit-trail'
  | 'days'
  | 'new-task'
  | 'description'
  | 'summary-is-required'
  | 'recurrence'
  | 'one-time'
  | 'weekly'
  | 'monthly'
  | 'summary'
  | 'high'
  | 'low'
  | 'medium'
  | 'visibility-team-policy'
  | 'visibility-personal-policy'
  | 'start-date-required'
  | 'start-date'
  | 'create-first-task'
  | 'assigned-to'
  | 'nobody'
  | 'created-by'
  | 'updated-without-date'
  | 'completed'
  | 'created'
  | 'due-date'
  | 'no-task'
  | 'disable-filters'
  | 'tasks-grid'
  | 'create-refill'
  | 'import-form'
  | 'no-form-templates'
  | 'lose-data-creating-form'
  | 'no-submission-found'
  | 'submissions'
  | 'search-submissions'
  | 'submission'
  | 'no-services-booking'
  | 'form-preview'
  | 'form-not-editable'
  | 'form-imported-text'
  | 'form-not-imported-text'
  | 'confirm-to-delete-name'
  | 'task-types'
  | 'make-default'
  | 'confirm-delete-availability-implicit'
  | 'default-availability'
  | 'confirming-lose-current-form'
  | 'confirm-delete-availability'
  | 'confirm-to-make'
  | 'edit-date-override'
  | 'confirm-delete-service-implicit'
  | 'something-went-wrong-implicit'
  | 'no-availability'
  | 'new-availability'
  | 'appointment-filters'
  | 'task-details'
  | 'are-you-sure-to-delete-task'
  | 'are-you-sure-to-resolve-task'
  | 'must-valid-domain'
  | 'no-access'
  | 'form-name'
  | 'show-grid'
  | 'task-appear-grid'
  | 'vow-ivr'
  | 'help-support'
  | 'internal-demos'
  | 'event-logs'
  | 'have-second-insurance'
  | 'subscribe-to-webhooks'
  | 'realtime-updates'
  | 'enabled'
  | 'disabled'
  | 'overwrite-defaults'
  | 'notifications'
  | 'no-notifications'
  | 'no-notifications-subtitle'
  | 'authentication-failed-generic'
  | 'add-manually'
  | 'relationship-to-me-is-required'
  | 'at'
  | 'preview'
  | 'form-not-editable'
  | 'form-preview'
  | 'dob-age'
  | 'form-name'
  | 'mark-all-as-read'
  | 'mark-as-read'
  | 'arabic'
  | 'continuation-disclaimer-register'
  | 'inactive'
  | 'submissions'
  | 'last-modified'
  | 'copy-url'
  | 'allergies-info-not-available'
  | 'info-not-available'
  | 'active'
  | 'no-edit'
  | 'imported';

